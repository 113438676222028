<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <template v-slot:activator="{ on }">
      <slot name="button" :on="on" :upd="updateData">
        <v-btn
          :disabled="!dataPrice.price && !dataPrice.anteaters"
          color="#FFB74D"
          x-large
          rounded
          v-on="on"
          @click="updateData"
          >{{ labelButton }}</v-btn
        >
      </slot>
    </template>
    <v-card>
      <v-card-text
        style="white-space: pre-line"
        class="headline font-weight-bold"
        v-text="dataStudio.studioName"
      />
      <v-card-text v-text="`Стоимость - ${totalCost}`" />
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model.trim="localUser.firstName"
                  color="blue lighten-4"
                  label="First Name"
                  class="purple-input"
                  type="text"
                  counter
                  :rules="userDataRules"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model.trim="localUser.lastName"
                  color="blue lighten-4"
                  label="Last Name"
                  class="purple-input"
                  type="text"
                  counter
                  :rules="userDataRules"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model.trim="localUser.country"
                  color="blue lighten-4"
                  label="Country"
                  class="purple-input"
                  type="text"
                  counter
                  :rules="userDataRules"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model.trim="localUser.postalCode"
                  color="blue lighten-4"
                  class="purple-input"
                  label="Postal Code"
                  type="text"
                  counter
                  :rules="userDataRules"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model.trim="localUser.city"
                  color="blue lighten-4"
                  label="City"
                  class="purple-input"
                  type="text"
                  counter
                  :rules="userDataRules"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model.trim="localUser.address"
                  color="blue lighten-4"
                  label="Address"
                  class="purple-input"
                  type="text"
                  counter
                  :rules="userDataRules"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="mr-2">
        <v-card-text
          v-if="dataPrice.price"
          :class="{ 'caption font-weight-regular': $vuetify.breakpoint.xs }"
          >Для обработки платежа Вы будете перенаправлены на страницу платформы
          <b>Stripe</b> (€)</v-card-text
        >
        <v-spacer />
        <v-btn
          :x-small="$vuetify.breakpoint.xs"
          :loading="loading"
          :disabled="!valid"
          color="#FB8C00"
          @click="payStripe"
          >Купить</v-btn
        >
      </v-card-actions>

      <v-card-actions
        v-if="
          dataPrice.price &&
            authorEmail === 'ekuchina@yandex.ru' &&
            !dataPrice.subscription &&
            !user.vip
        "
        class="mr-2"
      >
        <v-card-text :class="{ 'caption font-weight-regular': $vuetify.breakpoint.xs }"
          >Для обработки платежа Вы будете перенаправлены на страницу платформы
          <b>ЮMoney</b> (Ru)</v-card-text
        >
        <v-spacer />
        <ju-money :data-price="dataPrice" :data-studio="dataStudio" :author-email="authorEmail" />
      </v-card-actions>
      <v-card-actions class="mr-2">
        <v-spacer />
        <v-btn :x-small="$vuetify.breakpoint.xs" color="blue darken-1" text @click="close"
          >Закрыть</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'DialogDataUser',
  components: {
    JuMoney: () => import('@/components/shared/JuMoney')
  },
  props: {
    dataPrice: {
      type: Object,
      required: true
    },
    dataStudio: {
      type: Object,
      required: true
    },
    typeExercise: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    valid: false,
    localUser: {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      country: '',
      postalCode: ''
    },
    userDataRules: [v => !!v || 'Введите данные', v => v.length <= 30 || 'Hе более 30 символов']
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      loading: state => state.shared.loading
    }),
    totalCost() {
      if (!this.dataPrice.anteaters) {
        return `${this.dataPrice.price}€`
      } else if (!this.dataPrice.price) {
        return `${this.dataPrice.anteaters}F`
      } else {
        return `${this.dataPrice.price}€  и  ${this.dataPrice.anteaters}F`
      }
    },
    labelButton() {
      if (this.typeExercise === 'studios') {
        return 'Купить Студию'
      } else if (this.dataStudio.checkNeurographics) {
        return 'Купить Нейрографику'
      } else if (this.typeExercise === 'calendars') {
        return 'Купить Календарь'
      } else {
        return 'Купить'
      }
    },
    author() {
      return this.$store.getters.author(this.dataStudio.author)
    },
    authorEmail() {
      return this.author ? this.author.email : '7276242@gmail.com'
    },
    listOfPaidStudios() {
      let listStudios = []
      if (this.dataStudio.childNodes && this.dataStudio.childNodes.length) {
        listStudios = this.dataStudio.childNodes.map(item => item.id)
      }
      listStudios.push(this.dataStudio.id)
      return listStudios.join('/')
    }
  },
  methods: {
    updateData() {
      this.localUser.firstName = this.user.firstName || ''
      this.localUser.lastName = this.user.lastName || ''
      this.localUser.address = this.user.address || ''
      this.localUser.city = this.user.city || ''
      this.localUser.country = this.user.country || ''
      this.localUser.postalCode = this.user.postalCode || ''
    },
    payStripe() {
      if (this.user.vip) {
        this.$store
          .dispatch('changeDataUser', {
            payments: this.listOfPaidStudios,
            vip: true
          })
          .then(() => {
            if (this.dataStudio.id !== this.dataPrice.titleStudiosPrice) {
              this.$store.dispatch('changeDataUser', {
                payments: this.dataPrice.titleStudiosPrice,
                vip: true
              })
            }
            if (this.dataPrice.typeExercise === 'studios') {
              this.$router.push(`/studios/home/${this.dataStudio.id}`)
            } else if (this.dataPrice.typeExercise === 'studiosGroup') {
              this.$router.push(`/studios/group/${this.dataPrice.titleStudiosPrice}`)
            } else if (this.typeExercise === 'calendars') {
              this.$router.push(`/calendar/${this.dataStudio.id}`)
            } else {
              this.dialog = false
            }
          })
      } else {
        if (this.dataPrice.price) {
          this.$store.dispatch('payStripe', {
            name: this.dataStudio.studioName,
            description: this.dataPrice.status
              ? `${this.dataPrice.status}, ${this.totalCost}`
              : this.totalCost,
            images: this.dataStudio.imageSrc,
            amount: +this.dataPrice.price * 100,
            currency: 'eur',
            quantity: 1,
            dataUser: this.localUser,
            metadata: {
              name: this.dataStudio.studioName,
              studioId: this.dataStudio.id,
              typeExercise: this.dataPrice.typeExercise,
              titleStudiosPrice: this.dataPrice.titleStudiosPrice,
              anteaters: this.dataPrice.anteaters,
              typeGroup: this.dataPrice.typeGroup,
              childNodes: this.listOfPaidStudios,
              authorEmail: this.authorEmail,
              userName: `${this.localUser.firstName} ${this.localUser.lastName}`
            },
            subscription: this.dataPrice.subscription,
            subscriptionIntervalCount: this.dataPrice.subscriptionIntervalCount
          })
        } else {
          this.$store
            .dispatch('changeDataUser', {
              anteaters: -this.dataPrice.anteaters,
              payments: this.listOfPaidStudios
            })
            .then(() => {
              if (this.dataStudio.id !== this.dataPrice.titleStudiosPrice) {
                this.$store.dispatch('changeDataUser', {
                  payments: this.dataPrice.titleStudiosPrice
                })
              }
            })
            .then(() => {
              if (this.dataPrice.typeExercise === 'studios') {
                this.$router.push(`/studios/home/${this.dataStudio.id}`)
              } else if (this.dataPrice.typeExercise === 'studiosGroup') {
                this.$router.push(`/studios/group/${this.dataPrice.titleStudiosPrice}`)
              } else if (this.typeExercise === 'calendars') {
                this.$router.push(`/calendar/${this.dataStudio.id}`)
              } else {
                this.dialog = false
              }
            })
            .then(() => {
              this.$store.dispatch('nodeMailer', {
                userName: `${this.user.firstName} ${this.user.lastName}`,
                userEmail: this.user.email,
                subject: 'Куплено за муравьеды',
                text: `${this.dataPrice.anteaters} муравьедов за ${this.dataStudio.studioName}`
              })
            })
        }
      }
      if (
        !(
          this.localUser.firstName === this.user.firstName &&
          this.localUser.lastName === this.user.lastName &&
          this.localUser.address === this.user.address &&
          this.localUser.city === this.user.city &&
          this.localUser.country === this.user.country &&
          this.localUser.postalCode === this.user.postalCode
        )
      ) {
        this.$store.dispatch('changeDataUser', this.localUser)
      }
    },
    close() {
      this.dialog = false
    }
  }
}
</script>

<style scoped />
